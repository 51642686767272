<div class="modal" uid="modal-free-therm">
  <div class="modal__title">
    {{ 'txt-want-a-free-thermometer' | translate }}
  </div>
  <div class="modal__content">
    <app-button
      uid="modal-free-therm-button-no"
      text="white"
      color="purple"
      label="{{ 'txt-skip-free-therm' | translate }}"
      (click)="api().select(false)"
    />
    <app-button
      uid="modal-free-therm-button-yes"
      text="white"
      color="purple"
      label="{{ 'txt-accept-free-therm' | translate }}"
      (click)="api().select(true)"
    />
  </div>
</div>
